import * as THREE from 'three'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js'
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js'
import { mergeVertices } from 'three/addons/utils/BufferGeometryUtils.js'
import CustomShaderMaterial from 'three-custom-shader-material/vanilla'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import wobbleVertexShader from './shaders/wobble/vertex.glsl'
import wobbleFragmentShader from './shaders/wobble/fragment.glsl'

gsap.registerPlugin(ScrollTrigger);


// Base ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Environment map
const rgbeLoader = new RGBELoader()

rgbeLoader.load('./studio_small_08_1k.hdr', (environmentMap) =>
    {
        environmentMap.mapping = THREE.EquirectangularReflectionMapping
        // scene.background = environmentMap
        scene.environment = environmentMap
    })


// Wobble Sphere ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Material

const uniforms = {
    uTime: new THREE.Uniform(0),
    uPositionFrequency: new THREE.Uniform(0.5),
    uTimeFrequency: new THREE.Uniform(0.2),
    uStrength: new THREE.Uniform(0.3),
    uWarpPositionFrequency: new THREE.Uniform(0.2),
    uWarpTimeFrequency: new THREE.Uniform(0.05),
    uWarpStrength: new THREE.Uniform(2),
    uColorA: new THREE.Uniform(new THREE.Color('#FCF7F8')),
    uColorB: new THREE.Uniform(new THREE.Color('#ADB1BA'))
}

const material = new CustomShaderMaterial({
    // CSM
    baseMaterial: THREE.MeshPhysicalMaterial,
    vertexShader: wobbleVertexShader,
    fragmentShader: wobbleFragmentShader,
    uniforms: uniforms,
    silent: true,

    // MeshPhysicalMaterial
    metalness: 0.3,
    roughness: 0,
    color: '#ffffff',
    transmission: 0,
    ior: 1.5,
    thickness: 1.5,
    transparent: true,
    wireframe: false
})

const depthMaterial = new CustomShaderMaterial({
    // CSM
    baseMaterial: THREE.MeshDepthMaterial,
    vertexShader: wobbleVertexShader,
    uniforms: uniforms,
    silent: true,

    // MeshDepthMaterial
    depthPacking: THREE.RGBADepthPacking
})


// Tweaks
// gui.add(uniforms.uPositionFrequency, 'value', 0, 2, 0.001).name('uPositionFrequency')
// gui.add(uniforms.uTimeFrequency, 'value', 0, 2, 0.001).name('uTimeFrequency')
// gui.add(uniforms.uStrength, 'value', 0, 2, 0.001).name('uStrength')
// gui.add(material, 'metalness', 0, 1, 0.001)


// gui.add(uniforms.uWarpPositionFrequency, 'value', 0, 2, 0.001).name('uWarpPositionFrequency')
// gui.add(uniforms.uWarpTimeFrequency, 'value', 0, 2, 0.05).name('uWarpTimeFrequency')
// gui.add(uniforms.uWarpStrength, 'value', 0, 2, 0.001).name('uWarpStrength')
// gui.addColor(debugObject, 'colorA').onChange(() => uniforms.uColorA.value.set(debugObject.colorA))
// gui.addColor(debugObject, 'colorB').onChange(() => uniforms.uColorB.value.set(debugObject.colorB))

// gui.add(material, 'roughness', 0, 1, 0.001)
// gui.add(material, 'transmission', 0, 1, 0.001)
// gui.add(material, 'ior', 0, 10, 0.001)
// gui.add(material, 'thickness', 0, 10, 0.001)


// Geometry
let geometry = new THREE.IcosahedronGeometry(1.7, 50)
geometry = mergeVertices(geometry)
geometry.computeTangents()

// Mesh
const wobble = new THREE.Mesh(geometry, material)
wobble.customDepthMaterial = depthMaterial
wobble.receiveShadow = true

scene.add(wobble)




// Wobble Slider ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const sliders = document.querySelectorAll('.slider');

sliders.forEach(slider => {
    slider.addEventListener('input', function() {
        const sliderValue = slider.value;

        const uniformName = slider.getAttribute('data-uniform');
        if (uniformName) {
            uniforms[uniformName].value = sliderValue;
        }

        const materialProperty = slider.getAttribute('data-material');
        if (materialProperty) {
            material[materialProperty] = sliderValue;
        }
    });
});



// Lights ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// const directionalLight = new THREE.DirectionalLight('#ffffff', 3)
// directionalLight.position.set(0, 0, 1)
// scene.add(directionalLight)



// Sizes ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})



// Camera ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Group
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 10 //zoom out otherwise sphere is behind
camera.position.y = 0.2 //zoom out otherwise sphere is behind
camera.position.x = -0.2 //zoom out otherwise sphere is behind

cameraGroup.add(camera)



// Renderer ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.toneMapping = THREE.ACESFilmicToneMapping
renderer.toneMappingExposure = 1
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


// Scroll ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// // Select all .section elements
// const sections = document.querySelectorAll('.section');


// // Loop through each .section element
// sections.forEach((section, index) => {
//     // Create a ScrollTrigger for each section
//     ScrollTrigger.create({
//         trigger: section,
//         start: "top center", // Trigger when the top of the section reaches the center of the viewport
//         end: "bottom center", // Trigger when the bottom of the section reaches the center of the viewport
//         onToggle: self => {
//             // const visible = self.isActive ? 'in' : 'out';
//             // console.log(`Section ${index + 1} is now ${visible} view.`);
//             if (self.isActive) {
//                 // Check if it's section 2
//                 switch (index) {
//                     case 0:
//                         gsap.to(wobble.position, { duration: 1.5, x: 0, y: 0, z: 0, ease: "power2.inOut" });
//                         break;
//                     case 1:
//                         gsap.to(wobble.position, { duration: 1.5, x: -2.5, y: 0, z: 0, ease: "power2.inOut" });
//                         break;
//                     case 2:
//                         gsap.to(wobble.position, { duration: 1.5, x: 5, y: 0, z: 0, ease: "power2.inOut" });
//                         break;
//                     case 3:
//                         gsap.to(wobble.position, { duration: 1.5, x: 0, y: 5, z: 0, ease: "power2.inOut" });
//                         break;
//                     case 4:
//                         gsap.to(wobble.position, { duration: 1.5, x: 0, y: 0, z: 0, ease: "power2.inOut" });
//                         break;
//                     default:
//                         gsap.to(wobble.position, { duration: 1.5, x: 0, y: 0, z: 0, ease: "power2.inOut" });
//                         break;
//                 }
//             }
//         }

//     });
// });




// Get Cursor Position ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const cursor = {}
cursor.x = 0
cursor.y = 0

window.addEventListener('mousemove', (event) =>
{
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
})



// Animate ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    uniforms.uTime.value = elapsedTime

    // Animate camera
    const parallaxX = - cursor.x * 1
    const parallaxY =  cursor.y * 0.5
    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime

    // // Animate meshes
    //     mesh1.rotation.x += deltaTime * 0.1
    //     mesh1.rotation.y += deltaTime * 0.12

    renderer.render(scene, camera)

    window.requestAnimationFrame(tick)
}

tick()















// HTML Related ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Original and hidden titles
const originalTitle = document.title;
const hiddenTitle = "Come back soon!";

// Event listener for visibility change
document.addEventListener('visibilitychange', function() {
    if (document.hidden) {
        document.title = hiddenTitle;
    } else {
        document.title = originalTitle;
    }
});


// Position wobble on scroll ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const sections = document.querySelectorAll('.section');

sections.forEach((section, index) => {
    let targetPosition = { x: 0, y: 0, z: 0 };

    switch(index) {
        case 0:
            targetPosition = { x: 0, y: 0, z: 0 };
            break;
        case 1:
            targetPosition = { x: 0, y: 10, z: 0 };
            break;
        case 2:
            targetPosition = { x: 0, y: 10, z: 0 };
            break;
        case 3:
            targetPosition = { x: 0, y: 10, z: 0 };
            break;
        case 4:
            targetPosition = { x: 0, y: 10, z: 0 };
            break;
            
    }

    gsap.timeline({
        scrollTrigger: {
        trigger: section,
        start: "-50% center",
        end: "center center",
        scrub: true,
        // markers: true
        }
    }).to(wobble.position, {
        duration: 3,
        x: targetPosition.x,
        y: targetPosition.y,
        z: targetPosition.z,
        ease: "power2.inOut"
    });
});



// Change color menu ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const madeWith = document.querySelector('.madeWith');
const btnMenu = document.querySelector('.btnMenu');
const header = document.querySelector('header');

const backgrounds = [
    { element: document.querySelector('.bg--title'), color: 'var(--color-accent)' },
    { element: document.querySelector('.bg--question'), color: 'var(--color-main)' },
    { element: document.querySelector('.bg--greetings'), color: 'var(--color-main)' },
    { element: document.querySelector('.bg--about'), color: 'var(--color-main)' },
    { element: document.querySelector('.bg--resume'), color: 'var(--color-main)' },
    { element: document.getElementById('projects'), color: 'var(--color-main)' },
    { element: document.getElementById('contact'), color: 'var(--color-accent)' }
];

const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5 // Trigger when 10% of the target is visible
};

const observerCallback = (entries) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            madeWith.style.color = entry.target.dataset.color;
            btnMenu.style.color = entry.target.dataset.color;
            header.style.borderRight = `2px solid ${entry.target.dataset.color}`;
        } 
    });
};

const observer = new IntersectionObserver(observerCallback, observerOptions);

backgrounds.forEach(background => {
    if (background.element) {
        background.element.dataset.color = background.color;
        observer.observe(background.element);
    }
});








// Menu Expand ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

document.addEventListener('DOMContentLoaded', function() { 

    const body = document.body;
    const header = document.querySelector('header');
    const nav = document.querySelector('nav'); 
    const menuBtn = document.querySelector('.btnMenu');
    const closeBtn = document.querySelector('.btnMenu--close');
    const navLinks = document.querySelectorAll('li a');

    menuBtn.addEventListener('mouseover', function() {
        menuBtn.style.color = getComputedStyle(document.documentElement).getPropertyValue("--color-text").trim(); // Change text color on hover
    });
    menuBtn.addEventListener('mouseout', function() {
        menuBtn.style.color = getComputedStyle(document.documentElement).getPropertyValue("--color-accent").trim(); // Change text color on hover
    });

    menuBtn.addEventListener('click', function() {        
        nav.classList.toggle('active');
        menuBtn.style.display = 'none';        
        closeBtn.style.display = "block"
        // madeWith.style.display = 'none';        
        body.classList.add('no-scroll'); // disable scroll
    });

    closeBtn.addEventListener('click', function() {
        nav.classList.toggle('active');
        menuBtn.style.display = 'block';
        closeBtn.style.display = "none"
        // madeWith.style.display = 'block';        
        body.classList.remove('no-scroll');
    });

    // Close the expanded menu when clicking on a link
    navLinks.forEach(link => {
        link.addEventListener('click', function() {
          menuBtn.style.display = 'block';
          closeBtn.style.display = "none"

          body.classList.remove('no-scroll');
        });
    });

    // Smooth scrolling to anchor links
    navLinks.forEach(anchor => {
        anchor.addEventListener('click', function(e) {
            e.preventDefault();

            const targetId = this.getAttribute('href').substring(1);
            const targetElement = document.getElementById(targetId);
            nav.classList.toggle('active');

            window.scrollTo({
                top: targetElement.offsetTop,
                behavior: 'smooth'
            });
        });
    });

    

 // Emoji bounce on hover ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const madeWith = document.querySelector('.madeWith');
    const madeWithHidden = document.querySelector('.madeWith--hidden');
    const tlEmoji = gsap.timeline({ paused: true});

    madeWithHidden.addEventListener('mouseover', function(){

        if (!tlEmoji.isActive()) { // Play if not already bouncing
            tlEmoji.to('.emoji', { duration: 0.15, y: -12, ease: 'power2.out' }) 
              .to('.emoji', { duration: 0.15, y: 0, ease: 'power2.inOut' }) 
              .to('.emoji', { duration: 0.1, y: -8, ease: 'power2.out' }) 
              .to('.emoji', { duration: 0.15, y: 0, ease: 'power2.inOut' })
              .to('.emoji', { duration: 0.1, y: -5, ease: 'power2.out' }) 
              .to('.emoji', { duration: 0.15, y: 0, ease: 'power2.inOut' }); 
    
            tlEmoji.play();
        }
    })



    // Change colors ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    function getCssVariable(variableName) {
        return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
    }

    const themes = [         
        { colors: ['#79c1f1', '#fd5b3e', "#FFE9DB"], emoji: '💙' }, // navy blue / coral / white text
        { colors: ['#FF6700', '#EBEBEB', "#27282B"], emoji: '🧡' }, // orange / white        
        { colors: ['#39D094', '#6320EE', "#F4EEE5"], emoji: '💚' }, // bright green / neon blue 
        { colors: ['#341A82', '#8E7BED', "#F4EEE5"], emoji: '💜' }, // dark purple / light purple
        { colors: ['#9BC1BC', '#ED6A5A', "#F4F1BB"], emoji: '🩷' }, // vintage green / coral / yellow text
        { colors: ['#EE96AF', '#FF1F22', "#EAE0CC"], emoji: '💙' }, // pink / red /beige text
        { colors: ['#474A2C', '#E38635', "#F5D2B3"], emoji: '🧡' }, // forest green / orange / beige text
        { colors: ['#d4ff00', '#000000', "#FF221F"], emoji: '💙' }, // yellos / black /red text
        { colors: ['#ADFF2F', '#FF6347', "#27282B"], emoji: '💚' }, // Lime / Orange        
        { colors: ['#4A9A85', '#FFF584', "#00422D"], emoji: '💚' }, // Green / Yellow        
        { colors: ['#154751', '#0E958F', "#df367c"], emoji: '💙' }, // dark green / green / orange text
        { colors: ['#CC998D', '#ECEBE4', "#056152"], emoji: '💙' }, // brown / beige / dark green text
        { colors: ['#090736', '#262858', "#FFE96B"], emoji: '💛' }, // navy blue / dblue / yellow text
        { colors: ['#274E42', '#FFB4A2', "#F4EEE5"], emoji: '🩷' }, // dark green / coral pink
        { colors: ['#95715F', '#C0E8F9', "#27282B"], emoji: '🤎' }, // Brown / Baby blue
        { colors: ['#18181A', '#2F3D3C', "#00FFE5"], emoji: '🖤' }, // black / white
        { colors: ['#0047E0', '#78CAE2', "#F4EEE5"], emoji: '💙' }, // electric blue / light turquoise
        { colors: ['#BAA3FF', '#C84630', "#F4EEE5"], emoji: '💜' }, // Violet / Tomato red
        { colors: ['#FAB2EA', '#719798', "#27282B"], emoji: '🩷' }, // baby pink / turquoise
        { colors: ['#751A2A', '#CC8B86', "#F4EEE5"], emoji: '❤️' }, // dark red / dark light pink
        { colors: ['#FFD86D', '#BB006A', "#27282B"], emoji: '💛' }, // Orange / pink        
        { colors: ['#FCF7F8', '#ADB1BA', "#2F3D3C"], emoji: '🤍' }, // white
    ];
    
    // Random color on refresh
    // let random = Math.floor(Math.random() * colorPairs.length);

    // const [accentColor, mainColor, textColor] = colorPairs[random];

    // document.documentElement.style.setProperty('--color-accent', accentColor);
    // document.documentElement.style.setProperty('--color-main', mainColor);
    // document.documentElement.style.setProperty('--color-text', textColor);
    // uniforms.uColorA.value.set(accentColor);
    // uniforms.uColorB.value.set(mainColor);

    let currentThemeIndex = 0;

    var change = document.querySelectorAll('.change-color');

    function changeColors() {
        const { colors, emoji } = themes[currentThemeIndex];
        const [accentColor, mainColor, textColor] = colors;

        document.documentElement.style.setProperty('--color-accent', accentColor);
        document.documentElement.style.setProperty('--color-main', mainColor);
        document.documentElement.style.setProperty('--color-text', textColor);

        uniforms.uColorA.value.set(accentColor);
        uniforms.uColorB.value.set(mainColor);
        
        // Move to the next pair (loop back to beginning if reached end)
        currentThemeIndex = (currentThemeIndex + 1) % themes.length;

        // reset color of email letters
        change.forEach(function(letter) {
            letter.style.color = accentColor; // Change 'red' to any color you prefer
        });
        // Reset menu button color
        menuBtn.style.color = getComputedStyle(document.documentElement).getPropertyValue(accentColor).trim(); // Change text color on hover
    
        // Change the heart emoji
        // document.querySelector('.emoji').textContent = emoji;

                
        // const heart = document.querySelector('.emoji');

        // // Change the heart emoji
        // if (isEmojiSupported(heart)) {
        //     document.querySelector('.emoji').textContent = emoji;
        // } else {
        //     document.querySelector('.emoji').textContent = "♥︎";
        //   }


    }

    document.querySelector('.btnTheme').addEventListener('click', changeColors);

 // Letter Grow on hover ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const letters = document.querySelectorAll('.letter');

    letters.forEach(letter => {
        const hasChangeColorClass = letter.classList.contains('change-color');

        let tl = gsap.timeline({ paused: true });
    
        // Play the timeline on hover for the current letter
        letter.addEventListener('mouseover', function() {
            tl.to(letter, {
                duration: 0.4,
                ease: 'power2.inOut',
                "font-variation-settings": '"wght" 700',
                color: hasChangeColorClass ? getCssVariable('--color-text') : undefined
            }, 0)
            .to(letter, {
                duration: 0.8,
                ease: 'power2.inOut',
                delay: 0.1,
                "font-variation-settings": '"wght" 400',
                color: hasChangeColorClass ? getCssVariable('--color-accent') : undefined
            }, 0.4);

            tl.restart(); 
    
            // Animate the corresponding letter in h2 for hidden "wij"
            const letterValue = letter.getAttribute('data-letter');
            const h2Letter = document.querySelector(`h2 .letter[data-letter="${letterValue}"]`);
    
            if (h2Letter) {
                let h2Tl = gsap.timeline({ paused: true });
    
                h2Tl.to(h2Letter, {
                    duration: 0.4,
                    ease: 'power2.inOut',
                    "font-variation-settings": '"wght" 700',
                });
    
                h2Tl.to(h2Letter, {
                    duration: 0.8,
                    ease: 'power2.inOut',
                    delay: 0.1,
                    "font-variation-settings": '"wght" 400',
                });
    
                h2Tl.restart(); // Restart the timeline from the beginning
            }
        });
    });
    



    
// Rotate SVG ///////////////////////////////////////////////////////////////////////////////////////////////////////////:

    const animation = gsap.to("path", {
        rotation: -400,
        duration: 50,
        transformOrigin: "center center",
        repeat: -1
    });

    var rotate = gsap
    .timeline({
        scrollTrigger: {
        trigger: "html",
        scrub: 0.2,
        start: "top top",
        end: "+=2000",
        }
    })
    .to("svg", {
        rotation: 360,
        duration: 1,
        ease: "none"
    });
    
    ScrollTrigger.addEventListener("scrollStart", () => animation.pause());
    ScrollTrigger.addEventListener("scrollEnd", () => animation.play());



    // Belgium local time ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    function getCurrentBelgiumTime() {
        const now = new Date();
        const belgiumTime = new Date(now.toLocaleString('en-US', { timeZone: 'Europe/Brussels' }));
        const hours = belgiumTime.getHours();
        const minutes = belgiumTime.getMinutes();
        return {
            hours: hours % 12 || 12,
            minutes: minutes.toString().padStart(2, '0'),
            period: hours >= 12 ? 'PM' : 'AM'
        };
    }
    
    // Function to update the time display
    function updateTimeDisplay() {
        const timeDisplays = document.querySelectorAll('.time');
        const belgiumTime = getCurrentBelgiumTime();
        const timeString = `<p>${belgiumTime.hours}<span class="blink"> : </span>${belgiumTime.minutes} ${belgiumTime.period}<br>Currently in Belgium</p>`;
    
        timeDisplays.forEach(timeDisplay => {
            timeDisplay.innerHTML = timeString;
        });
    }
    
    updateTimeDisplay(); // Update time when page loads
    
    setInterval(updateTimeDisplay, 60000); // Update time every minute
    




    // Scroll to next section if name is entered /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Change text if name is entered //////////////////////////////////////////////////////////////////////////////////////////////

    const nameInput = document.getElementById('nameInput');
    const greetings = document.getElementById('greetings');
    const msg = document.querySelector('.msg');

    const nameSpan = document.querySelector('.greetings__title--name');
    const greetingsText = document.querySelector('.greetings__text');

    nameSpan.style.marginBottom = '-8vw'; 
    greetingsText.style.marginTop = '-3vw';    
   

    nameInput.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' && nameInput.value.trim() !== '') {
            // Change text at input field
            msg.innerHTML = `It's nice putting a name to the face !` ; 

            // Smooth scroll to next section
            const nextSection = document.querySelector('.bg--greetings');
            
            window.scrollTo({
                top: nextSection.offsetTop,
                behavior: 'smooth'
            });

            nameSpan.style.marginBottom = '0';
            greetingsText.style.marginTop = '2vw';    

            // Change greetings text
            const name = nameInput.value.trim();
            greetings.innerHTML = `<h2 class="greetings__title"> Howdy,<span class="greetings__title--name">${name}</span></h2>` ;
        }
    });


    



// Clip path ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
const clipText = document.querySelector('.clip__text p');
const clipElement = document.querySelector('.clip__text--hidden');
const clipContainer = document.querySelector('.clip__container');

function updateContainerHeight() { clipContainer.style.height = `${clipText.offsetHeight}px`; }

updateContainerHeight();

// Update the container height if window is resized
window.addEventListener('resize', updateContainerHeight);

let isAnimating = false;

function clipPathAnimation(event, radius){
    const rect = clipContainer.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top + (window.innerHeight * 0.32);

    clipElement.style.clipPath = `circle(${radius} at ${x}px ${y}px)`;
    isAnimating = false;
}

function handleMouseMove(event) {
    if (!isAnimating) {
        isAnimating = true;
        requestAnimationFrame(() => clipPathAnimation(event, '14vw'));
    }
}


clipContainer.addEventListener('mousemove', handleMouseMove);

clipContainer.addEventListener('mouseleave', (event) => {
    clipPathAnimation(event, '0');
});

clipContainer.addEventListener('mouseenter', (event) => {
    clipPathAnimation(event, '14vw');
});





// Horizontal Scroll ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

let sections = gsap.utils.toArray(".panel");

let scrollTween = gsap.to(sections, {
    xPercent: -100 * (sections.length - 1),
    ease: "none", // <-- IMPORTANT!
    scrollTrigger: {
      trigger: ".container",
      pin: true,
      scrub: 0.1,
      //snap: directionalSnap(1 / (sections.length - 1)),
      end: "+=3000"
    }
  });

// ScrollTrigger.defaults({markers: {startColor: "white", endColor: "white"}});




// Change li according to active project ////////////////////////////////////////////////////////////////////////////////
const pins = ['#pin0', '#pin1', '#pin2', '#pin3', '#pin4'];
const lastPin = '#pin5';

const index = document.querySelector('.index');
const li = document.querySelectorAll('.index li');
const titleProject = document.querySelector('.projects__header h2');

// set first li to active
if (li.length > 0) {
    li[0].classList.add("active");
    titleProject.classList.add("active");
    index.classList.add("active");
}


function handlePin(pin) {
    // current pin
    const pinIndex = pins.indexOf(pin);

    // Reset all li
    li.forEach((item) => {
        item.classList.remove("active");
        titleProject.classList.remove("active");
        index.classList.remove("active");
        madeWith.classList.remove("black");
        header.style.borderRight = `2px solid #27282B`;
    });

    // Add active on the next li 
    if (pinIndex >= 0 && pinIndex < li.length) {
        li[pinIndex].classList.add("active");
    }
    if (pinIndex == 0 || pinIndex == 1 || pinIndex == 3) {
        titleProject.classList.add("active");
        index.classList.add("active");   
        btnMenu.style.color = "black";
        madeWith.style.color = "black";
        header.style.borderRight = `2px solid #27282B`;

    }
    if (pinIndex == 2 || pinIndex == 4 ) {
        btnMenu.style.color = "white";
        madeWith.style.color = "white";
        header.style.borderRight = `2px solid #F4EEE5`;
    }
}

function handleLeavePin(pin) {
    const pinIndex = pins.indexOf(pin);

    li.forEach((item, index) => {
        item.classList.remove("active");
    });

    titleProject.classList.remove("active");
    index.classList.remove("active");
    header.style.borderRight = `2px solid #F4EEE5`;

    if (pinIndex == 1 || pinIndex == 2 || pinIndex == 4) {
        titleProject.classList.add("active");
        index.classList.add("active");
        btnMenu.style.color = "black";
        madeWith.style.color = "black";
        header.style.borderRight = `2px solid #27282B`;
    }
    if (pinIndex == 0 || pinIndex == 3 ) {
        madeWith.style.color = "white";
        btnMenu.style.color = "white";
        header.style.borderRight = `2px solid #F4EEE5`;
    }
    
    // Set cactive on the previous li
    if (pinIndex > 0 && pinIndex < li.length) {
        li[pinIndex - 1].classList.add("active");
    } else {
        // If first pin, keep active
        li[0].classList.add("active");
        titleProject.classList.add("active");
        index.classList.add("active");
    }  
}





// Pin projects /////////////////////////////////////////////////////////////////////////////////////////

pins.forEach(pin => {
  ScrollTrigger.create({
    // markers: { startColor: "blue", endColor: "blue" },
    trigger: pin,
    start: "top top",
    endTrigger: lastPin,
    end: "top top", 
    pin: true,
    pinSpacing: false,
    scrub: true,
    // snap: {
    //     snapTo: 0, // Snap to the start of the trigger element
    //     duration: {min: 0.2, max: 0.5}, // Optional: control the snapping duration
    //     ease: "power1.inOut", // Optional: control the easing of the snapping
    //     delay: 0 // Optional: delay before the snap starts
    //   },
    onEnter: () => handlePin(pin),
    onLeaveBack: () => handleLeavePin(pin),
  });
});

// Pin background 
const container = document.querySelector('.bg--projects');

ScrollTrigger.create({
  trigger: container,
  start: "top top",
  endTrigger: lastPin,
  end: "bottom top", 
  pin: true,
  pinSpacing: false
});

// Pin header
const proHeader = document.querySelector('.projects__header');

const tlHeader = gsap.timeline({
    scrollTrigger: {
      trigger: proHeader,
      start: "top top",
      endTrigger: lastPin,
      end: "top top",
      pin: true,
      pinSpacing: false,
      toggleClass: "active",
    }
  });
  
tlHeader.scrollTrigger.spacer.classList.add('pinSpacer');




// Visit link /////////////////////////////////////////////////////////////////////////////////////////////

// const projectImg = document.querySelector('.project__img');
// const projectLink = document.querySelector('.project__link');

// // Function to check if mouse is within element's bounding box
// function isMouseInBounds(element, mouseX, mouseY) {
//     const rect = element.getBoundingClientRect();
//     return (
//         mouseX >= rect.left &&
//         mouseX <= rect.right &&
//         mouseY >= rect.top &&
//         mouseY <= rect.bottom
//     );
// }


// // Mousemove event listener on document to track mouse position
// document.addEventListener("mousemove", function(e) {
//     const mouseInBounds = isMouseInBounds(projectImg, e.clientX, e.clientY);
    
//     if (mouseInBounds) {
//         console.log("Cursor is inside the bounding box.");

//         gsap.to(projectLink, { 
//             scale: 1.2, 
//             duration: 0.3, 
//             ease: "power2.out",  
//         });

//         gsap.set(".project__link", {xPercent: -50, yPercent: -50});

//         let xSetter = gsap.quickSetter(".project__link", "x", "px") //apply it to the #id element's x property and append a "px" unit
//         let ySetter = gsap.quickSetter(".project__link", "y", "px") //apply it to the #id element's x property and append a "px" unit

//         window.addEventListener("mousemove", e => {  
//         xSetter(e.x)
//         ySetter(e.y)
//         });

//     }
//     else{
//         gsap.to(projectLink, { scale: 0, duration: 0.3, ease: "power2.out" });
//     }
// });



// document.querySelectorAll('.project').forEach(project => {
//     const link = project.querySelector('.project__link');

//     project.addEventListener('mousemove', (e) => {
//         const rect = project.getBoundingClientRect();
//         const x = e.clientX - rect.left + 250;
//         const y = e.clientY - rect.top + 100;

//         // link.style.position = 'absolute';
//         link.style.left = `${x}px`;
//         link.style.top = `${y}px`;
//     });

//     // project.addEventListener('mouseleave', () => {
//     //     link.classList.remove('active');
//     // });
// });





// Cursor ///////////////////////////////////////////////////////////////////////

// var cursor = {
//     delay: 14,
//     _x: 0,
//     _y: 0,
//     endX: (window.innerWidth / 2),
//     endY: (window.innerHeight / 2),
//     cursorVisible: true,
//     cursorEnlarged: false,
//     $dot: document.querySelector('.cursor-dot'),
//     $outline: document.querySelector('.cursor-dot-outline'),
    
//     init: function() {
//         // Set up element sizes
//         this.dotSize = this.$dot.offsetWidth;
//         this.outlineSize = this.$outline.offsetWidth;
        
//         this.setupEventListeners();
//         this.animateDotOutline();
//     },
    
    
//     setupEventListeners: function() {
//         var self = this;
        
//         // Anchor hovering
//         document.querySelectorAll('a').forEach(function(el) {
//             el.addEventListener('mouseover', function() {
//                 self.cursorEnlarged = true;
//                 self.toggleCursorSize();
//             });
//             el.addEventListener('mouseout', function() {
//                 self.cursorEnlarged = false;
//                 self.toggleCursorSize();
//             });
//         });
        
//         // Click events
//         document.addEventListener('mousedown', function() {
//             self.cursorEnlarged = true;
//             self.toggleCursorSize();
//         });
//         document.addEventListener('mouseup', function() {
//             self.cursorEnlarged = false;
//             self.toggleCursorSize();
//         });
  
  
//         document.addEventListener('mousemove', function(e) {
//             // Show the cursor
//             self.cursorVisible = true;
//             self.toggleCursorVisibility();
  
//             // Position the dot
//             self.endX = e.pageX;
//             self.endY = e.pageY;
//             self.$dot.style.top = self.endY + 'px';
//             self.$dot.style.left = self.endX + 'px';
//         });
        
//         // Hide/show cursor
//         document.addEventListener('mouseenter', function(e) {
//             self.cursorVisible = true;
//             self.toggleCursorVisibility();
//             self.$dot.style.opacity = 1;
//             self.$outline.style.opacity = 1;
//         });
        
//         document.addEventListener('mouseleave', function(e) {
//             self.cursorVisible = true;
//             self.toggleCursorVisibility();
//             self.$dot.style.opacity = 0;
//             self.$outline.style.opacity = 0;
//         });
//     },
    
//     animateDotOutline: function() {
//         var self = this;
        
//         self._x += (self.endX - self._x) / self.delay;
//         self._y += (self.endY - self._y) / self.delay;
//         self.$outline.style.top = self._y + 'px';
//         self.$outline.style.left = self._x + 'px';
        
//         requestAnimationFrame(this.animateDotOutline.bind(self));
//     },
    
//     toggleCursorSize: function() {
//         var self = this;
        
//         if (self.cursorEnlarged) {
//             self.$dot.style.transform = 'translate(-50%, -50%) scale(0.75)';
//             self.$outline.style.transform = 'translate(-50%, -50%) scale(1.8)';
//         } else {
//             self.$dot.style.transform = 'translate(-50%, -50%) scale(1)';
//             self.$outline.style.transform = 'translate(-50%, -50%) scale(1)';
//         }
//     },
    
//     toggleCursorVisibility: function() {
//         var self = this;
        
//         if (self.cursorVisible) {
//             self.$dot.style.opacity = 1;
//             self.$outline.style.opacity = 1;
//         } else {
//             self.$dot.style.opacity = 0;
//             self.$outline.style.opacity = 0;
//         }
//     }
//   }
  
//   cursor.init();
  
  
  
  
  
  
//   var cursor = {
//     delay: 14,
//     _x: 0,
//     _y: 0,
//     endX: (window.innerWidth / 2),
//     endY: (window.innerHeight / 2),
//     $dot: document.querySelector('.cursor-dot'),
//     $outline: document.querySelector('.cursor-dot-outline'),
    
//     init: function() {        
//         this.setupEventListeners();
//         this.animateDotOutline();
//     },
    
    
//     setupEventListeners: function() {
//         var self = this;
  
//         document.addEventListener('mousemove', function(e) {
//             self.endX = e.pageX;
//             self.endY = e.pageY;
//             self.$dot.style.top = self.endY + 'px';
//             self.$dot.style.left = self.endX + 'px';
//         });
        
//     },
    
//     animateDotOutline: function() {
//         var self = this;
        
//         self._x += (self.endX - self._x) / self.delay;
//         self._y += (self.endY - self._y) / self.delay;
//         self.$outline.style.top = self._y + 'px';
//         self.$outline.style.left = self._x + 'px';
        
//         requestAnimationFrame(this.animateDotOutline.bind(self));
//     },
    
//   }
  
//   cursor.init();
  
});


